import axios from 'axios'
import errorCode from '@/utils/errorCode'
import apiUrl from '@/config/api'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  baseURL: apiUrl,
  // 超时值
  timeout: 60000
})

// Request拦截器
service.interceptors.request.use(config => {
  // 设置Token
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const message = errorCode[code] || res.data.msg || errorCode['default']
    if (code === -401) {
        console.log('err' + message)
    } else if (code === 500) {
      return Promise.reject(new Error(message))
    } else if (code !== 200) {
      return Promise.reject(res.data)
    } else {
      return res.data
    }
  },
  error => {
    console.log('err' + error)
    return Promise.reject(error)
  }
)

export default service
