import request from '@/utils/request'

// 开奖结果列表
export function DrawList(query) {
  return request({
    url: '/api/canadaTwoEight/list',
    method: 'get',
    params: query
  })
}

// 获取开奖结果信息
export function GetInfo(query) {
  return request({
    url: '/api/canadaTwoEight/getInfo',
    method: 'get',
    params: query
  })
}

// 预测列表
export function Forecast(query) {
  return request({
    url: '/api/canadaTwoEight/forecast',
    method: 'get',
    params: query
  })
}

// 今日统计
export function Today() {
  return request({
    url: '/api/canadaTwoEight/today',
    method: 'get',
  })
}

// 走势统计
export function Trend(query) {
  return request({
    url: '/api/canadaTwoEight/trend',
    method: 'get',
    params: query
  })
}

// 往期数据统计
export function Total() {
  return request({
    url: '/api/canadaTwoEight/total',
    method: 'get',
  })
}
