import { createApp } from 'vue'
import '@/assets/styles/bootstrap.css'
import '@/assets/styles/analyze.css'
import '@/assets/styles/index.css'
import '@/assets/styles/kit.css'
import '@/assets/styles/lottery.css'
import 'element-plus/dist/index.css'
import App from './App.vue'

import {
    ElButton,
    ElSelect,
    ElOption,
    ElIcon,
    ElContainer,
    ElAside,
    ElHeader,
    ElMain,
    ElTable,
    ElTableColumn,
    ElTabs,
    ElTabPane,
    ElCard,
    ElCol,
    ElRow,
    ElFormItem,
    ElInput,
    ElForm,
    ElDialog,
    ElTag,
} from "element-plus";

import { CountDown, Col, Dialog} from 'vant';

const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}

const app = createApp(App);

const components = [
    ElButton,
    ElSelect,
    ElOption,
    ElIcon,
    ElContainer,
    ElAside,
    ElHeader,
    ElMain,
    ElTable,
    ElTableColumn,
    ElTabs,
    ElTabPane,
    ElCard,
    ElCol,
    ElRow,
    ElFormItem,
    ElInput,
    ElForm,
    ElDialog,
    ElTag,
    CountDown,
    Col,
    Dialog,
];

components.forEach((component) => {
    app.component(component.name, component);
});

app.mount("#app");
