import request from '@/utils/request'

// 广告列表
export function AdsList(query) {
  return request({
    url: '/api/baseAds/list',
    method: 'get',
    params: query
  })
}
